@import '/styles/base.scss';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--theme-primary);
  color: var(--theme-copy);
}

.image {
  overflow: hidden;
  width: 100%;
  height: auto;
}

.logo,
.logo svg {
  width: auto;
  max-width: 14.4rem;
  min-height: 3.6rem;
  max-height: 3.6rem;

  @include lg {
    max-width: 20rem;
    min-height: 5rem;
    max-height: 5rem;
  }
}

.logo {
  margin: 2.4rem 2.4rem 0;

  @include lg {
    margin: 4rem 4rem 0;
  }

  svg,
  path,
  rect {
    fill: var(--theme-copy);
  }

  img {
    width: auto !important;
    height: auto !important;
    max-height: 100%;
  }
}

.content {
  padding: 2.4rem 2.4rem 4rem;

  @include lg {
    padding: 4rem 4rem 4.8rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.title {
  margin-bottom: 2.4rem;

  @include lg {
    margin-bottom: 3.2rem;
  }
}

.link {
  display: flex;
  align-items: flex-end;
  transition: color 0.3s ease;
}
