@import '/styles/base.scss';

.landingCard {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-primary);
  color: var(--theme-copy);
}

.cardContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.4rem 2.4rem 4rem;

  @include lg {
    padding: 4rem 4rem 4.8rem;
  }
}

.image {
  overflow: hidden;
  width: 100%;
  height: auto;

  img {
    object-fit: cover;
    transition: transform 1s ease;

    .landingCard:hover & {
      transform: scale(105%);
    }
  }
}

.title {
  margin-bottom: 0.8rem;
}

.description {
  margin-bottom: 4.8rem;

  @include lg {
    margin-bottom: 6.4rem;
  }
}

.link {
  display: inline-flex;
  align-items: flex-end;
  transition: opacity 1s ease;

  .landingCard:hover & {
    opacity: 0.65;
  }
}
