@import '/styles/base.scss';

.card {
  max-width: unset;
  background-color: var(--theme-primary);
}

.title {
  margin-bottom: 4.8rem;

  @include lg {
    margin-bottom: 6.4rem;
  }
}

.cardWrapper:not(:first-child),
.cardWrapperOdd {
  margin-top: 6.4rem;

  @include md {
    margin-top: 8.8rem;
  }

  @include lg {
    margin-top: 12rem;
  }
}

.cardInnerWrapper {
  @include sm-only {
    margin-right: -$margin-sm;
  }
}

.cardInnerWrapperOdd {
  @include sm-only {
    margin-right: 0;
    margin-left: -$margin-sm;
  }
}
