@import '/styles/base.scss';

.card {
  @include focus;
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2.4rem 4rem;
  background-color: var(--theme-primary);
  color: var(--theme-copy);
  isolation: isolate;

  @include lg {
    padding: 4rem 4rem 4.8rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.title {
  margin-bottom: 2.4rem;
}

.image {
  width: 96px;
  height: 120px;
  margin-bottom: 1.6rem;
}

.imagePlaceholder {
  width: 96px;
  height: 120px;
  position: relative;
  aspect-ratio: 4/5;
  background-color: var(--theme-primary);
  &::before {
    display: block;
    width: 100%;
    height: 100%;
    // darken the placeholder;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
    content: '';
  }
}

.imagePlaceholderIcon {
  position: absolute;
  right: 0;
  bottom: 11.6%;
  width: 71%;
  color: var(--theme-primary);
}

.author {
  z-index: -1;
  margin-top: 6.4rem;

  @include md {
    margin-top: 8.8rem;
  }
  @include lg {
    margin-top: 12rem;
  }

  &:has(.image) {
    margin-top: 8.8rem;
  }
}
