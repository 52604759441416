@import '/styles/base.scss';

.card {
  @include focus;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.4rem 2.4rem 4rem;
  background-color: var(--theme-primary);
  color: var(--theme-copy);

  @include lg {
    padding: 4rem 4rem 4.8rem;
  }

  &:hover {
    color: var(--theme-copy);

    .link {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }
}

.image {
  width: 9.6rem;
  img {
    object-fit: cover;
  }
}

.question {
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
  -webkit-hyphenate-limit-before: 6;
  -ms-hyphenate-limit-chars: 6;
  hyphenate-limit-chars: 8 6 2;
  hyphens: auto;
  word-break: break-word;

  @include lg {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
  }
}

.link {
  padding-top: $section-spacing-sm;

  @include md {
    padding-top: $section-spacing-md;
  }

  @include lg {
    padding-top: $section-spacing-lg;
  }
}
