@import '/styles/base.scss';

.newsCard {
  display: flex;
  max-width: 28.8rem;
  min-height: 33.7rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  color: var(--theme-copy);

  @include md {
    min-height: 34.2rem;
  }

  @include lg {
    max-width: 38.4rem;
    min-height: 39rem;
  }
}

.logoWrapper {
  svg {
    max-width: 14.4rem;
    min-height: 3.6rem;
    max-height: 3.6rem;

    @include lg {
      max-width: 20rem;
      min-height: 5rem;
      max-height: 5rem;
    }
  }

  svg,
  path,
  rect {
    fill: var(--theme-copy);
  }

  img {
    width: auto !important;
    height: auto !important;
    max-height: 100%;
  }
}

.logo {
  width: auto;
  max-width: 14.4rem;
  min-height: 3.6rem;
  max-height: 3.6rem;
  margin: 2.4rem;

  @include lg {
    max-width: 20rem;
    min-height: 5rem;
    max-height: 5rem;
    margin: 4rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.description {
  margin-bottom: 4rem;

  @include md {
    margin-bottom: 4.8rem;
  }

  @include lg {
    margin-bottom: 6.4rem;
  }
}

.eyebrow,
.description,
.link {
  padding: 0 2.4rem;

  @include lg {
    padding: 0 4rem;
  }
}

.link {
  padding-bottom: 4rem;

  @include lg {
    padding-bottom: 4.8rem;
  }
}
