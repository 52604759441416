@import '/styles/base.scss';

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  background-color: var(--theme-primary);
  color: var(--theme-copy);
}

.smallImage {
  overflow: hidden;
  width: 8.8rem;
  height: 8.8rem;
  border-radius: 50%;
  margin: 2.4rem 2.4rem 0;

  img {
    object-fit: cover;
  }

  @include lg {
    width: 9.6rem;
    height: 9.6rem;
    margin: 4rem 4rem 0;
  }
}

.coverImage {
  overflow: hidden;
}

.svgLogo {
  color: var(--theme-copy);

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate3d(-50%, -50%, 0);
  }

  svg,
  path,
  rect {
    fill: var(--theme-copy);
  }
}

.content {
  padding: 2.4rem 2.4rem 4rem;

  @include lg {
    padding: 4rem 4rem 4.8rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;

  @include md {
    margin-bottom: 0.4rem;
  }
}

.dateWrapper {
  margin-top: 2.4rem;
}

.ctaWrapper {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4rem;
  gap: 1.6rem;

  @include md {
    margin-top: 4.8rem;
  }

  @include lg {
    margin-top: 6.4rem;
  }
}
