@import '/styles/base.scss';

.quote {
  display: flex;
  flex-direction: column;
}

.quoteTopWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2.4rem;
}

.image {
  width: 40%;
  margin-right: 1.6rem;

  .twitter & {
    overflow: hidden;
    height: auto;
    border-radius: 50%;
    aspect-ratio: 1;
  }

  @include md {
    width: 20%;
  }

  img {
    object-fit: cover;
  }
}

.imageNarrow {
  @include md {
    width: 33.33%;
  }
}

.listenButton {
  top: -0.1rem;
  display: inline;
  margin-right: 1rem;
  // the negative margin prevents the button from distrurbing the line break into second line
  margin-bottom: -0.6rem;
  float: left;

  @include lg {
    top: 0.3rem;
    margin-bottom: 0;
  }
}

.quoteText {
  display: inline;
}

.link {
  margin-top: 2.4rem;
}

.username {
  display: block;
  // unset all default link styles
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.8rem;
}
