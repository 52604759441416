@import '/styles/base.scss';

.audioPlayer {
  position: relative;
  opacity: 1;
  transition: opacity 0.3s;
}

.audioPlayer-loading {
  // prevent hover state
  pointer-events: none;
}

.buttonIcon {
  width: 2rem;
}

.buttonIconLoading {
  position: relative;
  left: 50%;
  transform: translateX(-50%) scale(160%);
}

.audioPlayerLarge {
  display: flex;

  &.audioPlayer-error {
    justify-content: flex-start;
  }
}

.audioPlayerFixed {
  position: fixed;
  z-index: 1;
  right: $margin-sm;
  bottom: $margin-sm;

  @include md {
    right: $margin-md;
    bottom: $margin-md;
  }

  @include lg {
    right: 4rem;
    bottom: 4rem;
  }
}

.audioPlayerFaded {
  opacity: 0;
}

.playerLabelWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 1.6rem;
  margin-left: 0.4rem;
  opacity: 0;
}

.labelVisible {
  opacity: 1;
}

.reloadLabel {
  position: absolute;
  margin-left: 0.4rem;
}

.playerContent {
  display: flex;
  flex: 1;
  align-items: center;
}

.loadingIcon {
  position: absolute;
  width: 3.2rem;
}

.progressBarWrapper {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.3rem;
}

.progressBar {
  width: 100%;
  height: 0.3rem;
  flex: 1;
  border-radius: 10rem;
  background-color: rgba(255, 255, 255, 0.2);
}
.progressBarCompleted {
  height: 100%;
  border-radius: 10rem 0 0 10rem;
  background-color: $color-white;
}

.time {
  width: 4rem;
  color: rgba(255, 255, 255, 0.6);
  opacity: 0;
}

.timeVisible {
  opacity: 1;
}
